import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faArrowLeft,
  faHome,
} from '@fortawesome/free-solid-svg-icons';

const BlogNav = ({ previous, next }) => (
  <div className="blog-nav">
    {previous ? (
      <Link to={previous.fields.slug} rel="prev">
        <FontAwesomeIcon icon="arrow-left" title={previous.frontmatter.title} />
      </Link>
    ) : (
      <div></div>
    )}
    <Link to={'/'}>
      <FontAwesomeIcon icon="home" title="Home" />
    </Link>
    {next ? (
      <Link to={next.fields.slug} rel="next">
        <FontAwesomeIcon icon="arrow-right" title={next.frontmatter.title} />
      </Link>
    ) : (
      <div></div>
    )}
  </div>
);

export default BlogNav;

import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { DiscussionEmbed } from 'disqus-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faHome,
  faHeartbeat,
  faGlobeAfrica,
  faGrinSquintTears,
  faSlash,
} from '@fortawesome/free-solid-svg-icons';

import Bio from '../components/Bio';
import BlogDate from '../components/blog-date';
import BlogNav from '../components/blog-nav';

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faHome,
  faHeartbeat,
  faGlobeAfrica,
  faGrinSquintTears,
  faSlash,
);

const BlogPostTemplate = ({
  data: {
    markdownRemark: {
      frontmatter,
      html,
      id,
      fields: { slug },
    },
    site,
    next,
    previous,
  },
}) => {
  const { image, title } = frontmatter;
  const siteTitle = site.siteMetadata.title;
  const siteUrl = site.siteMetadata.siteUrl;
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: id, title: siteTitle, url: `${siteUrl}${slug}` },
  };
  return (
    <div className="body">
      <div className="blog-post">
        <div className="blog-content">
          <h1>{title}</h1>
          {image && <img src={image}></img>}
          <BlogDate {...frontmatter} />
          <Helmet title={`${title} | ${siteTitle}`} />
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <hr />
          <Bio />
          <BlogNav previous={previous} next={next} />
          <hr />
          <DiscussionEmbed {...disqusConfig} />
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        image
        published
      }
      fields {
        slug
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

export default BlogPostTemplate;

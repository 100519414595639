import React from 'react';
import { rhythm, scale } from '../utils/typography';

const BlogDate = ({ date }) => {
  return (
    <p
      className="blog-date"
      style={{
        ...scale(-1 / 5),
        marginBottom: rhythm(1),
        marginTop: rhythm(-1),
      }}
    >
      Written politely {date}.
    </p>
  );
};

export default BlogDate;

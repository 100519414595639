import React from 'react';

// Import typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';

import profilePic from './lucas_mcdaniel.jpg';
import { rhythm } from '../utils/typography';

class Bio extends React.Component {
  render() {
    return (
      <div className="bio">
        <img
          src={profilePic}
          alt={`Lucas McDaniel`}
          style={{
            marginRight: rhythm(1 / 2),
            width: rhythm(2),
            height: rhythm(2),
          }}
        />
        <p>
          Husband, father, teacher, musician, avid gamer, nature enthusiast, and
          passionate about the human condition.
        </p>
        {/* <p>
          Made with{' '}
          <a href="https://www.gatsbyjs.com/" target="_blank">
            Gatsby
          </a>
          {', '}
          <a href="https://unsplash.com/" target="_blank">
            Unsplash
          </a>
          {', and '}
          <a href="https://www.rebrandly.com/" target="_blank">
            Rebrandly
          </a>
          .
        </p> */}
      </div>
    );
  }
}

export default Bio;
